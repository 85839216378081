/* eslint-disable @nx/enforce-module-boundaries */
import { IconButton } from '@chakra-ui/react';
import {
  HandShakeSimpleIcon,
  ThreeDot,
} from '@mybridge/icons';

import {
  NotIntrestedSadSmileyIcon,
  BlockAndReportIcon,
  DeletePostIcon,
  EditPostIcon,
  PinPostIcon,
  MutualConnectionIcon
} from '@mybridge/icons/DashboardNewICons';

import { useDisclosure } from '@mybridge/ui/hooks';
// import { IconButton } from "@mybridge/ui/icon-button"
import { Menu, MenuButton, MenuItem, MenuList } from '@mybridge/ui/menu';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PostBoxContext } from '../context';
import { Spinner } from '@mybridge/ui/spinner';
import { Portal } from '@mybridge/ui';

export const PostActionsMenu = () => {
  const {
    post,
    notInterestedModal,
    blockReportModal,
    pinPostModal,
    handleFollow,
    following,
    followLoading,
    handleHandshake,
    handshaked,
    handshakeLoading,
    inCircle,
    deletePostModal,
    pinned,
    editPostModal,
    loggedIn,
  } = useContext(PostBoxContext);
  const { author } = post ?? {};
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const menuDisc = useDisclosure();

  const postActions = useMemo(
    () =>
      userProfileInfo?.id === author?.id
        ? [
            {
              title: `Delete Post`,
              icon: <DeletePostIcon width={20} height={20} />,
              onClick: () => {
                deletePostModal?.onOpen?.();
              },
            },
            {
              title: 'Edit Post',
              icon: <EditPostIcon width={20} height={20} />,
              onClick: () => {
                editPostModal?.onOpen?.();
              },
            },
            {
              title: `${pinned ? 'Unpin' : 'Pin'} to myProfile`,
              icon: <PinPostIcon width={20} height={20} />,
              onClick: () => {
                pinPostModal?.onOpen?.();
              },
            },
          ]
        : [
            {
              title: 'Not interested in this post',
              icon: <NotIntrestedSadSmileyIcon width={20} height={20} />,
              onClick: () => {
                notInterestedModal.onOpen();
                menuDisc?.onClose?.();
              },
            },
            // {
            //   title: following
            //     ? `Unfollow @${author?.slug}`
            //     : `Follow @${author?.slug}`,
            //   icon: followLoading ? (
            //     <Spinner size="xs" />
            //   ) : (
            //     <FollowIcon width={20} height={20} />
            //   ),
            //   onClick: () => {
            //     handleFollow();
            //   },
            // },
            ...(author?.field_audience_visibility?.WHO_CAN_SEND_FRIEND_REQ === 1 || inCircle
              ? [
                {
                  title: inCircle 
                    ? 'Mutual Connection' 
                    : handshaked
                    ? 'Withdraw handshake'
                    : 'Handshake Request',
                  icon: inCircle 
                    ? <MutualConnectionIcon width={20} height={20}/>
                    : handshakeLoading 
                    ? <Spinner size="xs" />
                    : <HandShakeSimpleIcon width={20} height={20} />,
                  onClick: () => {
                    handleHandshake();
                  },
                },
                ]
              : []),
            {
              title: 'Block and Report Post',
              icon: <BlockAndReportIcon width={20} height={20} />,
              onClick: () => {
                blockReportModal.onOpen();
                menuDisc?.onClose?.();
              },
            },
          ],
    [
      author,
      userProfileInfo,
      handleFollow,
      following,
      followLoading,
      handleHandshake,
      handshaked,
      handshakeLoading,
      inCircle,
    ]
  );


  return (
    <>
      <Menu {...menuDisc} closeOnSelect={false} zIndex="0">
        <MenuButton as={IconButton} size="xs" variant="ghost">
          <ThreeDot />
        </MenuButton>
        {loggedIn && (
          <Portal>
          <MenuList>
            {postActions.map?.((action, index) => (
              <MenuItem key={index} icon={action.icon} onClick={action.onClick}>
                {action.title}
              </MenuItem>
            ))}
          </MenuList>
          </Portal>
        )}
      </Menu>
    </>
  );
};
