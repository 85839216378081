/* eslint-disable @nx/enforce-module-boundaries */
import React from 'react';
import { Box, Flex, HStack, Square, Stack, VStack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { SeeMore } from '@mybridge/ui/see-more';
import { Skeleton } from '@mybridge/ui/skeleton';
import { Spinner } from '@mybridge/ui/spinner';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PostBoxContext, usePostBoxContext } from '../context';
import { PostBoxHeader } from '../header';
import { Heading, Text, Image, Button, Card } from '@mybridge/ui';
import { ForceSignInWrapper } from 'v4/components/force-sign-in-wrapper';
import { useRouter } from 'next/router';
import { EventDefaultImage } from '@mybridge/icons/EventDefaultImage';
import {
  EventCalendarLineIcon,
  EventClockIcon,
  EventHostIcon,
  LinkLineIcon,
  LocationLineIcon,
  Upload,
} from '@mybridge/icons';
import moment from 'moment';
import { getUserFullName } from 'v4/lib/commons';
import _ from 'lodash';
import { CALL_TO_ACTION } from 'v4/lib/data';
import Slider from 'react-slick';
import { AdCampaignDefaultImageIcon } from '@mybridge/icons/CampaignIcon';
import { ChildMediaDisplay } from '../child-media-display';

const SinglePostBody = ({ post, isOnlyPreview, postIndex }) => {
  const { push } = useRouter();
  const targetRef = useRef(null);
  const {
    hasInsights,
      isInFrame,
    showMedia = true,
    postModalLink,
    hasMedia,
    userProfileInfo,
    handleAdClicksRouting,
    
  } = useContext(PostBoxContext);
  const {
    id,
    content,
    repost,
    type,
    title,
    author,
    company,
    excerpt,
    readers,
    slug,
    media,
    start_time,
    end_time,
    attendees,
    ad_format_detail,
    ad_format,
    description,
    child_posts,
  } = post;
  const repostBoxContext = usePostBoxContext({ post: repost });
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const quoted = repost && (content?.length || media);
  const isArticle = type === 3;
  // if (isArticle) {
  //   console.log(post);
  // }

  const isEvent = type === 2;
  const isAdPost = type === 4;
  const isProfilePicPost = post?.content === 'USER_PROFILE_PIC_UPDATE';
  const isProfileBannerPost =
    post?.content === 'USER_PROFILE_BANNER_PIC_UPDATE';

  const attending = attendees?.find?.(
    (a) => a?.user?.id === userProfileInfo?.id
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  // console.log('childpost', postIndex, post?.id);
  // console.log('isInFrame', post?.media?.length);

  return (
    <>
       <Box style={{ width: '100%' }}>
          {content?.length && !isProfileBannerPost ? (
            <>
              <Link href={postModalLink?.(post?.parent_post)}>
                <Text
                  color="brandGray.700"
                  pl={2}
                  pr="60px"
                  pb={post?.media?.length > 0 ? 4 : 0}
                  dangerouslySetInnerHTML={{
                    __html: content
                      ?.replace?.('\r', '<br/>')
                      ?.replace('\n', '<br/>'),
                  }}
                  fontSize="md"
                />
              </Link>
            </>
          ) : (
            <></>
          )}

          {!isEvent && !isAdPost ? (
            <Stack
              pl={2}
              pr="60px"
              w="100%"
              minH={
                showMedia && hasMedia && media.length && !mediaLoaded
                  ? '300px'
                  : '0'
              }
              pos="relative"
            >
              {showMedia && media ? (
                <Link
                  maxH={'400px'}
                  overflow="hidden"
                  w="100%"
                  href={postModalLink?.(post?.id)}
                >
                  <ChildMediaDisplay
                    onLoaded={() => setMediaLoaded(true)}
                    post={post}
                  />
                </Link>
              ) : (
                ''
              )}
            </Stack>
          ) : (
            ''
          )}

          <Stack spacing={0}>
            {repost ? (
              <PostBoxContext.Provider value={repostBoxContext}>
                <Box
                  {...(quoted
                    ? {
                        mx: 4,
                        mt: hasMedia ? 0 : 2,
                        mb: hasInsights ? 0 : 2,
                        overflow: 'hidden',
                        border: '1px solid',
                        borderColor: 'gray.200',
                        borderRadius: 5,
                      }
                    : {})}
                >
                  {quoted ? <PostBoxHeader actionMenuEnabled={false} /> : ''}
                  <SinglePostBody />
                </Box>
              </PostBoxContext.Provider>
            ) : (
              ''
            )}
          </Stack>
        </Box>
    </>
  );
};

export default SinglePostBody;
